import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, mergeMap } from 'rxjs/operators';
import { GeneralService } from '../services/general.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private generalService: GeneralService, private notification: NzNotificationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = this.generalService.getToken() ?? '';
    let secureReq;
    // Don't add header to external APIs
    if (
      !request.url.includes("compliance")
      && !request.url.includes("paygate")
      && !request.url.includes("auth")
      && !request.url.includes("localhost")
      && !request.url.includes("sudocompliance.s3")
    ) {
      // console.log('no header');
      secureReq = request.clone();
    }
    else {
      // console.log('setting header', token);
      if (request.url.includes("sudocompliance.s3")) {
        secureReq = request.clone();
      } else if (request.url.includes("intercom")) {
        secureReq = request.clone();
      } else {
        secureReq = request.clone({
          setHeaders: {
            "Authorization": token
          }
        });
      }
    }

    return next.handle(secureReq).pipe(
      catchError((error) => {
        console.log(error);

        if (error.status === 403 && error.error.message == "Forbidden resource") {
          // this.generalService.logoutUser();
          this.notification.warning(error.error.message, '' + error.error.message, {
            nzClass: 'notification1',
          });
        }

        if (error.error.statusCode === 403 && error.error.message == "Expired token - Access Restricted!") {
          this.generalService.logoutUser();
        }

        if (error.error.statusCode === 401 && error.error.message == "Could not validate token with Authentication service - Access Restricted!") {
          this.generalService.logoutUser();
        }
        if (error.status === 500) {
          return throwError({
            statusCode: 500,
            message: 'An error occured. Please try again later.'
          });
        }
        else {
          return throwError("An error occured. Please try again later.");
        }
      })
    );
  }
}
