export const environment = {
  production: true,
  jwtTokenEncryptionKey: 'kqwdiuy4ert@aW',
  secretKey: 'Zr4u7x!A%D*G-KaPdSgUkXp2s5v8y/B?E(H+MbQeThWmYq3t6w9z$C&F)J@NcRfUjXn2r4u7x!A%D*G-KaPdSgVkYp3s6v8y/B?E(H+MbQeThWmZq4t7w!z$C&F)J@Nc',
  // apiUrl: 'https://paygate-compliance-api-sandbox.herokuapp.com/',
  apiUrl: 'https://paygate-compliance-api-prod.herokuapp.com/',
  // adminUrl: 'https://paygate-auth-api-sandbox.herokuapp.com/',
  // adminUrl: 'https://paygate-auth-jdr4bmno2q-nw.a.run.app/',
  adminUrl: 'https://auth-api.sudoafrica.network/'
};
