import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ComplianceGuard } from './auth/compliance.guard';


const routes: Routes = [
  {
    path: 'admin/two-fa',
    loadChildren: () => import('./pages/admin/two-fa/two-fa.module').then(m => m.TwoFaModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'admin/dashboard',
    loadChildren: () => import('./pages/admin/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance',
    loadChildren: () => import('./pages/compliance/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view-compliance',
    loadChildren: () => import('./pages/view-compliance/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user/document/:id',
    loadChildren: () => import('./pages/view-compliance/document-details/document-details.module').then(m => m.UserDocumentDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'documents/:id',
    loadChildren: () => import('./pages/document-details/document-details.module').then(m => m.DocumentDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invite/director',
    loadChildren: () => import('./pages/director/director-layout/director-layout.module').then(m => m.DirectorLayoutModule)
  },
  {
    path: 'invite/referee',
    loadChildren: () => import('./pages/referee/referee-layout/referee-layout.module').then(m => m.RefereeLayoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/general-layout/general-layout.module').then(m => m.GeneralLayoutModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }