import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { take, tap } from 'rxjs/operators';
import { EncryptStorage } from 'encrypt-storage';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  private _subject = new Subject<any>();
  emitedChange = this._subject.asObservable()

  setDataValue(event: any) {
    this._subject.next(event)
  }

  constructor(private readonly apiService: ApiService, private location: Location, public http: HttpClient) { }

  encryptStorage = new EncryptStorage(environment.jwtTokenEncryptionKey, {
    prefix: '@sudo',
    storageType: 'sessionStorage',
  });

  encryptLocalStorage = new EncryptStorage(environment.jwtTokenEncryptionKey, {
    prefix: '@sudo',
    storageType: 'localStorage',
  });

  goBack() {
    this.location.back();
  }

  formatDateISO(date: any): string {
    var d = new Date(date);
    return d.toISOString().split("T")[0];
  }

  logoutUser() {
    this.removeLoginData();
    window.location.replace('/');
    // this.router.navigate(['/signin'], { replaceUrl: true });
  }

  logoutCompliance() {
    this.removeLoginData();
    window.location.replace('/start-compliance');
    // this.router.navigate(['/signin'], { replaceUrl: true });
  }

  logout() {
    this.removeLoginData();
    window.location.replace('/');
    // this.router.navigate(['/signin'], { replaceUrl: true });
  }

  setToken(jwtToken: string) {
    this.encryptStorage.setItem('token', jwtToken);
  }

  setData(key: string, value: any) {
    this.encryptLocalStorage.setItem(key, value);
  }

  getData(key: string) {
    return this.encryptLocalStorage.getItem(key);
  }

  removeData(key: string) {
    this.encryptLocalStorage.removeItem(key);
  }

  getToken(): string {
    return this.encryptStorage.getItem('token')!;
  }

  removeLoginData() {
    this.encryptStorage.removeItem('token');
    this.encryptStorage.clear();
    this.encryptLocalStorage.clear();
    // this.encryptLocalStorage.removeItem('env');
  }

  getEnvironment() {
    return this.apiService.getEnvironment();
  }

  setEnvironment(env: string) {
    this.apiService.setEnvironment(env);
  }

  sendEmailOTP(data: any) {
    const opts = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    return this.apiService.post('entities/email/verify', data, opts);
  }

  validateEmailOTP(data: any) {
    const opts = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    return this.apiService.post('entities/email/verify/2fa', data, opts);
  }

  verifyIdentity(data: any) {
    
    return this.apiService.post('identities/verify', data, {});
  }

  validateIdentity(otpId: string, data: any) {
    return this.apiService.put(`identities/${otpId}/validate`, data, {});
  }

  validateEmailAddress(emailString: string) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (
      !!emailString &&
      typeof emailString === 'string' &&
      emailString.match(emailRegex)
    );
  }

  getAllCountries() {
    return this.apiService.getAllCountries().pipe(
      tap((countries: any) => {
        // Sort by name
        countries.sort(function (a: any, b: any) {
          var nameA = a.name.common.toUpperCase();
          var nameB = b.name.common.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })

        countries.map((value: any) => {
          value.name = value.name.common;
        });
      })
    );
  }

  copyText(text: any): boolean {
    const el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    return true;
  }

  changePINInputmode() {
    setTimeout(() => {
      let otps: HTMLCollection = document.getElementsByClassName('otp-input');
      for (let i = 0; i < otps.length; i++) {
        document.getElementsByClassName('otp-input')[i].setAttribute("inputmode", "numeric");
      }
    }, 500);
  }

  getBanks() {
    return this.http.get('/assets/json/banks.json');
  }
}
